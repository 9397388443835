import React, { useState } from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import { RATING_TYPES } from '../../constants/reviewConstant'
import MyRatingStar from '../MyRatingStar'
import AggregatedRatingRow from './AggregatedRatingRow'

const TyreDetail = ({ tyre, reviews }) => {
  const [tyreImgExist, setTyreImgExist] = useState(false)

  const totalSumOverallRating = reviews.reduce((acc, cur) => {
    return acc + cur.overallRating
  }, 0)

  const overallRating =
    reviews.length === 0 ? 0 : totalSumOverallRating / reviews.length

  const countWillRepurchase = reviews.filter(
    (review) => review.repurchase === true
  ).length

  const countNotRepurchase = reviews.filter(
    (review) => review.repurchase === false
  ).length

  const repurchaseRate =
    countWillRepurchase + countNotRepurchase === 0
      ? null
      : (
          (countWillRepurchase / (countWillRepurchase + countNotRepurchase)) *
          100
        ).toFixed(0)

  return (
    <>
      <Row className={tyreImgExist ? 'justify-content-center' : null}>
        <Col xs='6' md='3' className={tyreImgExist ? 'px-0 pt-4' : 'd-none'}>
          <Image
            onLoad={() => setTyreImgExist(true)}
            src={tyre.tyreImg}
            width='100%'
          />
        </Col>
        <Col xs='12' md='9'>
          <div className='small text-muted my-3'>
            {repurchaseRate !== null ? (
              <>{`${repurchaseRate}`}% of reviewers would purchase again</>
            ) : (
              ''
            )}
          </div>

          <h5>Overall Rating</h5>
          <MyRatingStar rating={overallRating} />
          <span className='ml-3 h2' style={{ verticalAlign: 'sub' }}>
            {overallRating.toFixed(1)}
          </span>

          <hr />

          {RATING_TYPES.map((type) => {
            return (
              <AggregatedRatingRow
                key={type.key}
                type={type}
                reviews={reviews}
              />
            )
          })}
        </Col>
      </Row>

      {tyre.description && (
        <>
          <hr />
          <h5>Description</h5>
          <Row>
            <Col lg='8' className='text-muted'>
              {tyre.description}
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default TyreDetail
