import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
  return (
    <footer>
      <Container className='py-4'>
        <Row>
          <Col md='4' className='my-4 px-5'>
            <h5>About us</h5>
            <hr />
            <div>
              We're here to make tyres easy to understand for everyone. By giving drivers a platform
              to share their experiences about tyres, we believe we can make driving a better
              experience. Read, write and share tyre reviews today.
            </div>
          </Col>
          <Col md='4' className='my-4 px-5'>
            <h5>Contact</h5>
            <hr />
            <div>If you have a question or can't find a tyre, please get in touch!</div>
            <div className='my-3'>Email: support@drivefurther.asia</div>
          </Col>
          <Col className='my-4 px-5'>
            <h5>Follow us</h5>
            <hr />
            <a href='https://www.facebook.com/drivefurtherMY/' target='_blank' rel='noreferrer'>
              <img src='/facebook-icon.png' alt='facebook' height='40' />
            </a>
          </Col>
        </Row>
        <div>
          <div className='text-center small pt-4'>
            <span className='mx-3'>&copy; 2022 Drive Further</span>
            <span className='mx-3 white'>
              <Link to='/terms'>Terms</Link>
            </span>
            <span className='mx-3 white'>
              <Link to='/privacy-policy'>Privacy Policy</Link>
            </span>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
