import React, { useState } from 'react'
import { Image } from 'react-bootstrap'

const HideableImage = (props) => {
  const [loaded, setLoaded] = useState(false)

  let { className = '' } = props

  if (!loaded) {
    className = className.replace('d-block', '') + ' d-none'
  }

  return (
    <Image {...props} className={className} onLoad={() => setLoaded(true)} />
  )
}

export default HideableImage
