import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import MyRatingStar from '../MyRatingStar'
import BlurEmail from '../BlurEmail'

const RecentReviewCard = ({ review }) => {
  const history = useHistory()

  const contentSize = 250

  const { data } = useSelector((state) => state.brands)

  const brandName = useMemo(() => {
    if (data.length > 0) {
      return data.find((brand) => brand._id === review.tyre.brand).name
    }
    return ''
  }, [data, review])

  const truncatedComment =
    review.comment.length > contentSize
      ? `${review.comment.slice(0, contentSize)}...`
      : review.comment

  const clickedHandler = () => {
    history.push(`/reviews/${review.tyre.slug}`)
  }

  return (
    <Card onClick={clickedHandler} style={{ cursor: 'pointer' }}>
      <Card.Body>
        <div className='mb-1 text-center'>
          <MyRatingStar sm rating={review.overallRating} />
        </div>
        <p
          style={{
            fontSize: '12px',
            textAlign: 'center',
          }}
        >
          <BlurEmail email={review.email} />
          <span className='text-muted'>{' reviewed '}</span>
          <span>{`${brandName} ${review.tyre.pattern}`}</span>
        </p>
        <p>{truncatedComment}</p>
      </Card.Body>
    </Card>
  )
}

export default RecentReviewCard
