import axios from 'axios'

const options = {
  headers: { 'Content-Type': 'application/json' },
}

if (process.env.NODE_ENV !== 'production') {
  options.baseURL = 'http://127.0.0.1:5000'
}

const API = axios.create(options)

export const fetchTyre = async (id) => {
  try {
    const { data } = await API.get(`/api/tyres/${id}`)
    return data
  } catch (error) {
    return {}
  }
}

export const fetchReviewsByTyreId = async (id) => {
  try {
    const { data } = await API.get(`/api/reviews?tyre=${id}`)
    return data
  } catch (error) {
    return []
  }
}

export default API
