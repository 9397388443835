import React from 'react'
import CreatableSizeSelect from '../CreatableSizeSelect'

const TyreRimField = ({ input }) => (
  <CreatableSizeSelect
    onChange={input.onChange}
    min={10}
    max={25}
    step={0.5}
    decimalPlace={1}
  />
)

export default TyreRimField
