import React from 'react'
import { FormControl } from 'react-bootstrap'

const CommentField = ({ input, meta }) => {
  return (
    <>
      <FormControl
        as='textarea'
        rows='8'
        aria-label='comment'
        placeholder='Tell us about your experience. Keep your feedback honest, helpful, and constructive.'
        {...input}
        isInvalid={meta.touched && meta.error}
      />
      <FormControl.Feedback type='invalid'>{meta.error}</FormControl.Feedback>
    </>
  )
}

export default CommentField
