import validator from 'validator'

export const required = (text) => {
  if (!text || text.trim().length === 0) {
    return 'This field is required.'
  }
  return undefined
}

export const isEmail = (text) => {
  if (!text || !validator.isEmail(text)) {
    return 'Invalid email.'
  }
  return undefined
}
