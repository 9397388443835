import { TRACKING_FAIL, TRACKING_REQUEST, TRACKING_SUCCESS } from '../constants/analyticsConstant'

export const trackReducer = (state = { loading: false, data: [] }, action) => {
  switch (action.type) {
    case TRACKING_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case TRACKING_SUCCESS:
      return {
        loading: false,
      }
    case TRACKING_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
