import React from 'react'

const BlurEmail = ({ email }) => {
  return (
    <span>
      {`${email.split('@')[0]}@`}
      <span className='blurry-text'>drivefurther.com</span>
    </span>
  )
}

export default BlurEmail
