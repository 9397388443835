import React from 'react'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap'

const RepurchaseField = ({ input }) => {
  return (
    <ToggleButtonGroup
      name='repurchase'
      value={input.value}
      onChange={input.onChange}
    >
      <ToggleButton
        variant={`${input.value === 1 ? 'primary' : 'light'}`}
        value={1}
      >
        Yes
      </ToggleButton>
      <ToggleButton
        variant={`${input.value === 2 ? 'primary' : 'light'}`}
        value={2}
      >
        No
      </ToggleButton>
      <ToggleButton
        variant={`${input.value === 3 ? 'primary' : 'light'}`}
        value={3}
      >
        Unsure
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default RepurchaseField
