import {
  TYRES_LIST_SUCCESS,
  SELECT_TYRE,
  TYRES_LIST_REQUEST,
  TYRES_LIST_FAIL,
} from '../constants/tyreConstant'

export const tyresReducer = (
  state = {
    loading: false,
    tyres: [],
    tyre: {},
  },
  action
) => {
  switch (action.type) {
    case TYRES_LIST_REQUEST:
      return { ...state, loading: true, error: null }
    case TYRES_LIST_SUCCESS:
      return { ...state, loading: false, tyres: action.payload }
    case TYRES_LIST_FAIL:
      return { ...state, loading: false, error: action.payload }
    case SELECT_TYRE:
      return { ...state, tyre: action.payload }
    default:
      return state
  }
}
