import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { fetchReviewsByTyreId } from '../api'
import ReviewCard from '../components/Review/ReviewCard'
import MyRatingStar from '../components/MyRatingStar'
import { Helmet } from 'react-helmet'
import pluralize from 'pluralize'
import CreateReviewBanner from '../components/Review/CreateReviewBanner'
import { selectTyre } from '../actions/tyreActions'
import TyreDetail from '../components/Review/TyreDetail'
import { REVIEW_SUBMISSION_FEEDBACK_ACKNOWLEDGE } from '../constants/reviewConstant'
import ReviewCardLoader from '../components/Review/ReviewCardLoader'
import { track } from '../actions/analyticsActions'

const ReviewScreen = ({ match, history }) => {
  const dispatch = useDispatch()

  const [reviews, setReviews] = useState([])
  const [loading, setLoading] = useState(true)

  const { tyres, tyre } = useSelector((state) => state.tyres)

  const { successMessage } = useSelector((state) => state.submitReview)

  useEffect(() => dispatch(selectTyre(match.params.slug)), [dispatch, match.params.slug, tyres])

  useEffect(() => {
    let isMounted = true
    if (tyre.id) {
      fetchReviewsByTyreId(tyre.id).then((data) => {
        if (isMounted) {
          setLoading(false)
          setReviews(data)
        }
      })
    }
    return () => {
      isMounted = false
    }
  }, [match.params.slug, tyre.id])

  useEffect(() => {
    dispatch(track('Review Page'))
    return () => dispatch({ type: REVIEW_SUBMISSION_FEEDBACK_ACKNOWLEDGE })
  }, [dispatch])

  const ratingHandler = (value) => {
    history.push(
      `/evaluate/${match.params.slug}?${queryString.stringify({
        overallRating: value,
      })}`
    )
  }

  return (
    <>
      <Helmet>
        <title>{tyre.name && tyre.name}</title>
        <meta name='description' content={`Reviews of ${tyre.name && tyre.name}`} />
      </Helmet>

      <Container>
        {successMessage && <Alert variant='success'>{successMessage}</Alert>}
        <CreateReviewBanner tyre={tyre} />
        <TyreDetail tyre={tyre} reviews={reviews} />
        <hr />
        <div className='mb-2'>
          <Link to={`/evaluate/${match.params.slug}`}>Write a review</Link>
        </div>
        <div>
          <MyRatingStar changeRating={ratingHandler} />
        </div>
        {loading && <ReviewCardLoader />}
        {!loading && reviews.length > 0 ? (
          <>
            <div className='h5 my-4'>{pluralize('review', reviews.length, true)}</div>
            {reviews
              .sort((r1, r2) => {
                const d1 = new Date(r1.createdAt)
                const d2 = new Date(r2.createdAt)

                if (d1 > d2) return -1
                else if (d1 < d2) return 1
                else return 0
              })
              .map((review) => (
                <ReviewCard key={review._id} review={review} />
              ))}
          </>
        ) : (
          <Alert variant='info' className='my-3'>
            There aren't any reviews for this tyre yet!
          </Alert>
        )}
      </Container>
    </>
  )
}

export default ReviewScreen
