import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import HideableImage from '../HideableImage'
import pluralize from 'pluralize'
import MyRatingStar from '../MyRatingStar'

const BrandRatingRow = ({ pattern, image, reviews, tyreSlug }) => {
  const repurchasesNotNull = reviews.filter((review) => review.repurchase !== null)

  const repurchaseRate =
    (repurchasesNotNull.filter((review) => review.repurchase).length / repurchasesNotNull.length) *
    100

  const averageRating =
    reviews.reduce((acc, review) => acc + review.overallRating, 0) / reviews.length

  return (
    <LinkContainer to={`/reviews/${tyreSlug}`}>
      <Row className='brand-row justify-content-center align-items-center py-3 mb-3'>
        <Col xs='9' md='3'>
          <h5 className='py-2'>{pattern}</h5>
          <HideableImage src={image} className='tyre-in-brand py-2' />
        </Col>
        <Col md='4'>
          {reviews.length > 0 && (
            <>
              <h5 className='pt-2'>Overall Rating</h5>
              <div className='pb-2'>
                <MyRatingStar rating={averageRating} />
                <span className='ml-3 h4' style={{ verticalAlign: 'sub' }}>
                  {averageRating.toFixed(1)}
                </span>
              </div>
            </>
          )}
          <h5 className='py-2'>{pluralize('review', reviews.length, true)}</h5>
          {repurchasesNotNull.length > 0 && (
            <div className='text-muted small py-2'>{`${repurchaseRate.toFixed(
              0
            )}% of reviewers would purchase again`}</div>
          )}
        </Col>
      </Row>
    </LinkContainer>
  )
}

export default BrandRatingRow
