import React from 'react'
import { Row, Col } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'

const BrandRatingRowLoader = () => {
  return (
    <>
      <Row className='brand-row justify-content-center align-items-center mb-3'>
        <Col xs='9' md='3'>
          <ContentLoader speed={0.5} viewBox='0 0 300 300'>
            <rect x='60' y='20' width='180' height='30' />
            <rect x='40' y='60' width='220' height='220' />
          </ContentLoader>
        </Col>
        <Col md='4'>
          <ContentLoader speed={0.5} viewBox='0 0 350 280'>
            <rect x='100' y='70' width='150' height='20' />
            <rect x='45' y='100' width='220' height='30' />
            <rect x='270' y='100' width='30' height='30' />
            <rect x='100' y='140' width='150' height='18' />
            <rect x='75' y='165' width='200' height='15' />
          </ContentLoader>
        </Col>
      </Row>
      <Row className='brand-row justify-content-center align-items-center mb-3'>
        <Col xs='9' md='3'>
          <ContentLoader speed={0.5} viewBox='0 0 300 300'>
            <rect x='60' y='20' width='180' height='30' />
            <rect x='40' y='60' width='220' height='220' />
          </ContentLoader>
        </Col>
        <Col md='4'>
          <ContentLoader speed={0.5} viewBox='0 0 350 280'>
            <rect x='100' y='70' width='150' height='20' />
            <rect x='45' y='100' width='220' height='30' />
            <rect x='270' y='100' width='30' height='30' />
            <rect x='100' y='140' width='150' height='18' />
            <rect x='75' y='165' width='200' height='15' />
          </ContentLoader>
        </Col>
      </Row>
      <Row className='brand-row justify-content-center align-items-center mb-3'>
        <Col xs='9' md='3'>
          <ContentLoader speed={0.5} viewBox='0 0 300 300'>
            <rect x='60' y='20' width='180' height='30' />
            <rect x='40' y='60' width='220' height='220' />
          </ContentLoader>
        </Col>
        <Col md='4'>
          <ContentLoader speed={0.5} viewBox='0 0 350 280'>
            <rect x='100' y='70' width='150' height='20' />
            <rect x='45' y='100' width='220' height='30' />
            <rect x='270' y='100' width='30' height='30' />
            <rect x='100' y='140' width='150' height='18' />
            <rect x='75' y='165' width='200' height='15' />
          </ContentLoader>
        </Col>
      </Row>
    </>
  )
}

export default BrandRatingRowLoader
