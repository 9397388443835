import API from '../api'
import {
  SELECT_TYRE,
  TYRES_LIST_FAIL,
  TYRES_LIST_REQUEST,
  TYRES_LIST_SUCCESS,
} from '../constants/tyreConstant'
import { extractErrorMessage } from '../utils'

export const fetchTyres = () => async (dispatch) => {
  try {
    dispatch({ type: TYRES_LIST_REQUEST })
    const { data } = await API.get('/api/tyres')
    dispatch({
      type: TYRES_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({ type: TYRES_LIST_FAIL, payload: extractErrorMessage(error) })
  }
}

export const selectTyre = (slug) => async (dispatch, getState) => {
  try {
    const { tyres } = getState().tyres

    const tyre = tyres.find((tyre) => tyre.slug === slug)

    dispatch({
      type: SELECT_TYRE,
      payload: tyre
        ? {
            id: tyre._id,
            slug,
            brandImg: tyre.brand.image,
            tyreImg: tyre.image,
            description: tyre.description,
            name: `${tyre.brand.name} ${tyre.pattern}`,
          }
        : {},
    })
  } catch (error) {}
}
