import React from 'react'
import { Card } from 'react-bootstrap'

const TermsScreen = () => {
  return (
    <>
      <h4 className='my-4 text-center'>Terms & Conditions</h4>
      <Card className='border-0 m-sm-5'>
        <Card.Body>
          <p>
            These Terms of Use (“Terms”) govern your use of the websites, mobile applications and
            services (collectively, the “Services”) provided by Drive Further.{' '}
          </p>
          <ol>
            <li>
              Acceptance of Terms
              <ol className='my-3'>
                <li>
                  Your use of our Services is subject to these Terms. By using the Services, you are
                  deemed to have accepted and agree to be bound by these Terms. We may make changes
                  to these Terms from time to time. We may notify you of such changes by any
                  reasonable means, including by posting the revised version of these Terms on this
                  website. Your use of the Services following changes to these Terms will constitute
                  your acceptance of those changes.{' '}
                </li>
              </ol>
            </li>
            <li>
              Ability to Accept Terms
              <ol className='my-3'>
                <li>
                  You affirm that you are either more than 18 years of age, or possess legal
                  parental or guardian consent, and are fully able and competent to enter into the
                  terms, conditions, obligations, affirmations, representations, and warranties set
                  forth in these Terms, and to abide by and comply with these Terms.
                </li>
              </ol>
            </li>
            <li>
              Services Access
              <ol className='my-3'>
                <li className='my-2'>
                  You are responsible for all access to our Services using your internet connection,
                  even if the access is by another person.{' '}
                </li>
                <li className='my-2'>
                  We will use reasonable efforts to ensure that the Services is available at all
                  times. However, we cannot guarantee that the Services or any individual function
                  or feature of the Services will always be available and/or error free. Our
                  Services may be unavailable during periods when we are implementing upgrades or
                  carrying our essential maintenance.{' '}
                </li>
              </ol>
            </li>
            <li>
              Your Use of the Services
              <ol className='my-3'>
                <li className='my-2'>
                  Your permission to use the Services is personal to you and non-transferable, and
                  you may not use our Services for commercial purposes. Your use of our Services is
                  conditional on your compliance with the rules of conduct set forth in these Terms
                  and you agree that you will not:{' '}
                </li>
                <ol className='my-3'>
                  <li className='my-2'>
                    Use the Services for any fraudulent or unlawful purpose;{' '}
                  </li>
                  <li className='my-2'>
                    Use the Services to defame, abuse, harass, stalk, threaten or otherwise violate
                    the rights of others, including without limitation others’ privacy rights or
                    rights of publicity;{' '}
                  </li>
                  <li className='my-2'>
                    Impersonate any person or entity, false state or otherwise misrepresent your
                    affliation with any person or entity in connection with the Site or express or
                    imply that we endorse any statement you make;{' '}
                  </li>
                  <li className='my-2'>
                    Interfere with or disrupt the operation of the Services or the servers or
                    networks used to make our Services available or violate any requirements,
                    procedures, policies or regulations of such networks;{' '}
                  </li>
                  <li className='my-2'>
                    Transmit or otherwise make available in connection with the Services any virus,
                    worm or other computer code that is harmful or invasive or may or is intended to
                    damage the operation of, or to monitor the use of, any hardware, software, or
                    equipment;{' '}
                  </li>
                  <li className='my-2'>
                    Reproduce, duplicate, copy, sell, resell or otherwise exploit for any commercial
                    purposes, any portion of, use of, or access to the Services;{' '}
                  </li>
                  <li className='my-2'>
                    Modify, adapt, translate, reverse engineer, decompile or disassemble any portion
                    of the Services;{' '}
                  </li>
                  <li className='my-2'>
                    Remove any copyright, trade mark or other proprietary rights notice from the
                    Services or materials originating from our Services;{' '}
                  </li>
                  <li className='my-2'>
                    Frame or mirror any part of our Services without our express prior written
                    consent;{' '}
                  </li>
                  <li className='my-2'>
                    Create a database by systematically downloading and storing the Services
                    content;{' '}
                  </li>
                  <li className='my-2'>
                    Use any manual or automatic device in any way to gather the Services content or
                    reproduce or circumvent the navigational structure or presentation of the
                    Services without our express prior written consent.{' '}
                  </li>
                </ol>
                <li className='my-2'>
                  We reserve the right to revoke these exceptions either generally or in specific
                  instances.
                </li>
              </ol>
            </li>
            <li>
              Third Party Websites
              <ol className='my-3'>
                <li className='my-2'>
                  Our Services may provide links to other websites and online resources. We are not
                  responsible for and do not endorse such external sites or resources. Your use of
                  third party websites and resources is at your own risk.{' '}
                </li>
              </ol>
            </li>
            <li>
              Intellectual Property
              <ol className='my-3'>
                <li className='my-2'>
                  The intellectual property rights in our Services and all of the text, pictures,
                  videos, graphics, user interfaces, visual interfaces, trademarks, logos,
                  applications, programs, computer code and other content made available on it are
                  owned by us and our licensors. You may not print or otherwise make copies of any
                  such content without our express prior permission{' '}
                </li>
              </ol>
            </li>
            <li>
              Limitation of Liability
              <ol className='my-3'>
                <li className='my-2'>
                  We provide the Services on an “as is” basis and make no representations as to the
                  quality, completeness or accuracy of any content made available on our Services.{' '}
                </li>
                <li className='my-2'>
                  The foregoing is a comprehensive limitation of liability that applies to all
                  damages of any kind, including (without limitation) compensatory, direct, indirect
                  or consequential damages, loss of data, income or profit, loss of or damage to
                  property and claims of third parties.{' '}
                </li>
              </ol>
            </li>
            <li>
              Collection of Personal Information
              <ol className='my-3'>
                <li className='my-2'>
                  We may collect and use information about you in accordance with our privacy
                  policy. You can view a copy of this policy by clicking here.{' '}
                </li>
              </ol>
            </li>
            <li>
              Duration of Terms
              <ol className='my-3'>
                <li className='my-2'>
                  These Terms are effective until terminated. We may, at any time and for any
                  reason, terminate your access to or use of our Services. If we terminate your
                  access to the Services you will not have the right to bring claims against us or
                  our affiliates with respect to such termination. We and our affiliates shall not
                  be liable for any termination of your access to the Services.{' '}
                </li>
              </ol>
            </li>
            <li>
              Governing Law
              <ol className='my-3'>
                <li className='my-2'>
                  These Terms of Use will be governed by and construed in accordance with the laws
                  of Malaysia, and the courts of Malaysia will have non-exclusive jurisdiction over
                  any claim or dispute arising under or in connection with these Terms.{' '}
                </li>
              </ol>
            </li>
          </ol>
        </Card.Body>
      </Card>
    </>
  )
}

export default TermsScreen
