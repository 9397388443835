import React from 'react'
import { Card, CardColumns } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'

const RecentReviewLoader = () => {
  return (
    <CardColumns>
      <Card>
        <Card.Body>
          <ContentLoader speed={0.5} viewBox='0 0 300 150'>
            <rect x='90' y='0' width='120' height='15' />
            <rect x='60' y='20' width='180' height='12' />
            <rect x='20' y='40' width='260' height='150' />
          </ContentLoader>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <ContentLoader speed={0.5} viewBox='0 0 300 150'>
            <rect x='90' y='0' width='120' height='15' />
            <rect x='60' y='20' width='180' height='12' />
            <rect x='20' y='40' width='260' height='150' />
          </ContentLoader>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <ContentLoader speed={0.5} viewBox='0 0 300 150'>
            <rect x='90' y='0' width='120' height='15' />
            <rect x='60' y='20' width='180' height='12' />
            <rect x='20' y='40' width='260' height='150' />
          </ContentLoader>
        </Card.Body>
      </Card>
    </CardColumns>
  )
}

export default RecentReviewLoader
