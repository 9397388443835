import React from 'react'
import ContentLoader from 'react-content-loader'

const TyreNameLoader = () => {
  return (
    <div style={{ width: 260, display: 'inline-block', marginBottom: 10 }}>
      <ContentLoader speed={0.5} viewBox='0 0 100 15'>
        <rect x='0' y='1' width='100' height='15' />
      </ContentLoader>
    </div>
  )
}

export default TyreNameLoader
