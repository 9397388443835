import React, { useState } from 'react'
import { Container, Image } from 'react-bootstrap'

const BrandHeader = ({ brand }) => {
  const [brandImgExist, setBrandImgExist] = useState(false)

  return (
    <div className='brand-header py-4 px-2'>
      <Container className='text-center'>
        <div className={brandImgExist ? null : 'd-none'}>
          <Image onLoad={() => setBrandImgExist(true)} src={brand?.image} rounded width='200' />
        </div>
        {!brandImgExist && <div className='h4 mt-2 mb-0'>{brand?.name}</div>}
      </Container>
    </div>
  )
}

export default BrandHeader
