import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from 'react-bootstrap'
import BrandHeader from '../components/Brand/BrandHeader'
import BrandRatingRow from '../components/Brand/BrandRatingRow'
import { fetchReviewsByBrand } from '../actions/reviewAction'
import { Helmet } from 'react-helmet'
import BrandRatingRowLoader from '../components/Brand/BrandRatingRowLoader'

const BrandScreen = ({ match }) => {
  const history = useHistory()

  const dispatch = useDispatch()

  const { tyres, loading: tyresLoading } = useSelector((state) => state.tyres)

  const { data } = useSelector((state) => state.brands)

  const { brandReviews } = useSelector((state) => state.reviews)

  const brand = useMemo(() => {
    return data.find((brand) => brand.name === match.params.name)
  }, [data, match.params.name])

  useEffect(() => {
    const tyre = tyres.find((tyre) => tyre.brand.name === match.params.name)

    if (tyres.length > 0 && !tyre) {
      history.push('/')
    } else if (tyre) {
      dispatch(fetchReviewsByBrand(match.params.name))
    }
  }, [history, dispatch, tyres, match.params.name])

  const reviewGroups = {}

  brandReviews.data.forEach((review) => {
    if (!(review.tyre._id in reviewGroups)) {
      reviewGroups[review.tyre._id] = []
    }
    reviewGroups[review.tyre._id].push(review)
  })

  const tyresNoReview = tyres.filter(
    (tyre) => tyre.brand.name === match.params.name && !(tyre._id in reviewGroups)
  )

  return (
    <>
      <Helmet>
        <title>Drive Further {match.params.name} Tyre Reviews</title>
        <meta name='description' content={`Tyre Reviews of ${match.params.name}`} />
      </Helmet>
      <BrandHeader brand={brand} />
      <Container className='text-center pt-5'>
        {tyresLoading || brandReviews.loading ? (
          <BrandRatingRowLoader />
        ) : (
          <>
            {Object.keys(reviewGroups).map((tyreId) => {
              const tyre = tyres.find((tyre) => tyre._id === tyreId)
              return (
                <BrandRatingRow
                  key={tyreId}
                  tyreSlug={tyre.slug}
                  pattern={tyre.pattern}
                  image={tyre.image}
                  reviews={reviewGroups[tyreId]}
                />
              )
            })}
            {tyresNoReview.map((tyre) => (
              <BrandRatingRow
                key={tyre._id}
                tyreSlug={tyre.slug}
                pattern={tyre.pattern}
                image={tyre.image}
                reviews={[]}
              />
            ))}
          </>
        )}
      </Container>
    </>
  )
}

export default BrandScreen
