import React from 'react'
import { useSelector } from 'react-redux'
import { CardColumns } from 'react-bootstrap'
import RecentReviewCard from './RecentReviewCard'
import RecentReviewLoader from './RecentReviewLoader'

const RecentReviews = () => {
  const { recentReviews } = useSelector((state) => state.reviews)

  return (
    <div className='recent-reviews-container py-5 px-3 px-sm-5 text-center'>
      <h5>
        {!recentReviews.loading && recentReviews.data.length === 0
          ? 'No recent reviews'
          : 'Recent reviews'}
      </h5>
      <br />
      {recentReviews.loading ? (
        <RecentReviewLoader />
      ) : (
        <CardColumns className=''>
          {recentReviews.data.map((review) => (
            <RecentReviewCard key={review._id} review={review} />
          ))}
        </CardColumns>
      )}
    </div>
  )
}

export default RecentReviews
