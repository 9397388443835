import {
  BRAND_REVIEWS_LIST_FAIL,
  BRAND_REVIEWS_LIST_REQUEST,
  BRAND_REVIEWS_LIST_SUCCESS,
  RECENT_REVIEWS_LIST_FAIL,
  RECENT_REVIEWS_LIST_REQUEST,
  RECENT_REVIEWS_LIST_SUCCESS,
  REVIEW_SUBMISSION_FEEDBACK_ACKNOWLEDGE,
  SUBMIT_REVIEW_FAIL,
  SUBMIT_REVIEW_REQUEST,
  SUBMIT_REVIEW_SUCCESS,
} from '../constants/reviewConstant'

export const reviewsReducer = (
  state = {
    recentReviews: {
      loading: false,
      data: [],
    },
    brandReviews: {
      loading: false,
      data: [],
    },
  },
  action
) => {
  switch (action.type) {
    case RECENT_REVIEWS_LIST_REQUEST:
      return {
        ...state,
        recentReviews: { data: [], loading: true },
      }
    case RECENT_REVIEWS_LIST_SUCCESS:
      return {
        ...state,
        recentReviews: { data: action.payload, loading: false },
      }
    case RECENT_REVIEWS_LIST_FAIL:
      return {
        ...state,
        recentReviews: {
          data: [],
          loading: false,
          error: action.payload,
        },
      }

    case BRAND_REVIEWS_LIST_REQUEST:
      return {
        ...state,
        brandReviews: { data: [], loading: true },
      }
    case BRAND_REVIEWS_LIST_SUCCESS:
      return {
        ...state,
        brandReviews: { data: action.payload, loading: false },
      }
    case BRAND_REVIEWS_LIST_FAIL:
      return {
        ...state,
        brandReviews: {
          data: [],
          loading: false,
          error: action.payload,
        },
      }
    default:
      return state
  }
}

export const submitReviewReducer = (
  state = {
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case SUBMIT_REVIEW_REQUEST:
      return { loading: true }
    case SUBMIT_REVIEW_SUCCESS:
      return {
        loading: false,
        successMessage: action.payload,
      }
    case SUBMIT_REVIEW_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    case REVIEW_SUBMISSION_FEEDBACK_ACKNOWLEDGE:
      return {
        loading: false,
      }
    default:
      return state
  }
}
