import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { tyresReducer } from './reducers/tyreReducers'
import { reviewsReducer, submitReviewReducer } from './reducers/reviewReducers'
import { reducer as formReducer } from 'redux-form'
import { brandsReducer } from './reducers/brandReducers'
import { trackReducer } from './reducers/analyticsReducers'

const reducer = combineReducers({
  brands: brandsReducer,
  tyres: tyresReducer,
  reviews: reviewsReducer,
  submitReview: submitReviewReducer,
  track: trackReducer,
  form: formReducer,
})

const initialState = {}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
