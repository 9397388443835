import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import EvaluateForm from '../components/Evaluate/EvaluateForm'
import { selectTyre } from '../actions/tyreActions'
import TyreNameLoader from '../components/Review/TyreNameLoader'
import BrandLoader from '../components/Review/BrandLoader'
import HideableImage from '../components/HideableImage'
import { track } from '../actions/analyticsActions'

const EvaluateScreen = ({ match, history }) => {
  const dispatch = useDispatch()
  const { tyres, tyre } = useSelector((state) => state.tyres)

  useEffect(() => dispatch(selectTyre(match.params.slug)), [dispatch, match.params.slug, tyres])

  useEffect(() => {
    dispatch(track('Tyre Page'))
  },[dispatch])
  return (
    <>
      <Helmet>
        <title>{`Rate ${tyre.name && tyre.name}`}</title>
        <meta name='description' content={`Write a review for ${tyre.name && tyre.name}`} />
      </Helmet>

      <div className='review-form-header py-4 px-2'>
        <Container className='review-form-header-content'>
          <Row className='h4 mb-0'>
            <Col xs='auto'>
              {tyre.brandImg ? (
                <HideableImage src={tyre.brandImg} rounded width='200' />
              ) : (
                <BrandLoader />
              )}
            </Col>

            <Col xs='12' className='mt-3'>
              {tyre.name ? tyre.name : <TyreNameLoader />}
            </Col>
          </Row>
        </Container>
      </div>

      <Container className='review-form my-4'>
        <EvaluateForm tyreId={tyre.id} />
      </Container>
    </>
  )
}

export default EvaluateScreen
