import React from 'react'
import { Form } from 'react-bootstrap'

const EmailField = ({ input, meta, placeholder }) => {
  return (
    <>
      <Form.Control
        type='email'
        {...input}
        placeholder={placeholder}
        isInvalid={meta.touched && meta.error}
      />
      <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>
    </>
  )
}

export default EmailField
