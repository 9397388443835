import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar } from 'react-bootstrap'

const Header = () => {
  return (
    <header>
      <Navbar className='px-sm-5' expand='md'>
        <LinkContainer to='/'>
          <Navbar.Brand>
            <img
              alt='drivefurther logo'
              src={process.env.PUBLIC_URL + '/DF_logo_3.png'}
              className='nav-logo my-1'
            />
          </Navbar.Brand>
        </LinkContainer>
      </Navbar>
    </header>
  )
}

export default Header
