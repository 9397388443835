import API from '../api'
import { BRAND_LIST_FAIL, BRAND_LIST_REQUEST, BRAND_LIST_SUCCESS } from '../constants/brandConstant'
import { extractErrorMessage } from '../utils'

export const fetchBrands = () => async (dispatch) => {
  try {
    dispatch({ type: BRAND_LIST_REQUEST })
    const { data } = await API.get('/api/brands')
    dispatch({ type: BRAND_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BRAND_LIST_FAIL,
      payload: extractErrorMessage(error),
    })
  }
}
