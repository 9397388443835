import React from 'react'
import StarRatings from 'react-star-ratings'

const MyRatingStar = ({
  rating,
  starRatedColor = 'rgb(255, 194, 66)',
  starHoverColor = 'rgb(255, 194, 66)',
  starSpacing = '1px',
  sm,
  md,
  ...props
}) => {
  return (
    <StarRatings
      rating={rating}
      starRatedColor={starRatedColor}
      starHoverColor={starHoverColor}
      starDimension={sm ? '20px' : md ? '30px' : '40px'}
      starSpacing={starSpacing}
      {...props}
    />
  )
}

export default MyRatingStar
