import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { RATING_TYPES } from '../../constants/reviewConstant'
import MyRatingStar from '../MyRatingStar'
import BlurEmail from '../BlurEmail'

const ReviewCard = ({ review }) => {
  return (
    <Card className='my-3'>
      <Card.Body>
        <div className='mb-3 '>
          <MyRatingStar rating={review.overallRating} md />
          <span className='ml-3 h5' style={{ verticalAlign: 'sub' }}>
            {review.overallRating.toFixed(1)}
          </span>
        </div>
        <Card.Title>{review.title}</Card.Title>

        <Row>
          <Col xs='12' lg='8' className='mb-3'>
            {review.comment}
          </Col>
          <Col>
            {RATING_TYPES.filter((type) => review[type.key] > 0).map((type) => (
              <Row key={type.key}>
                <Col className='text-muted' xs='4' lg='5'>
                  {type.label}
                </Col>
                <Col>
                  <MyRatingStar rating={review[type.key]} sm />
                  <span className='ml-3' style={{ verticalAlign: 'sub' }}>
                    {review[type.key].toFixed(1)}
                  </span>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <br />
        <small className='text-muted'>
          <div className='mb-2'>
            {review.width && review.profile && review.rimSize && (
              <span className='mr-3'>
                Size: {review.width}/{review.profile}R{review.rimSize}
              </span>
            )}
            {review.mileage > 0 && <span>Mileage: {review.mileage} km</span>}
          </div>
          {`${moment(review.createdAt).format('DD MMMM YYYY')} by `}
          <BlurEmail email={review.email} />
        </small>
      </Card.Body>
    </Card>
  )
}

export default ReviewCard
