import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import TyreNameLoader from './TyreNameLoader'
import BrandLoader from './BrandLoader'
import HideableImage from '../HideableImage'

const CreateReviewBanner = ({ tyre }) => {
  const { loading } = useSelector((state) => state.tyres)

  return (
    <div className='create-review-banner pt-3 pb-1'>
      {loading ? (
        <BrandLoader />
      ) : (
        <HideableImage src={tyre.brandImg} rounded width='200' className='d-block' />
      )}

      {loading ? (
        <TyreNameLoader />
      ) : (
        <h4 className='my-3 d-inline-block'>{tyre.name && tyre.name}</h4>
      )}

      <div className='create-review-link pb-sm-2'>
        <Link
          className='btn btn-lg d-block d-sm-inline-block btn-primary'
          to={`/evaluate/${tyre.slug}`}
          data-testid='write-a-review-1'
        >
          Write a review
        </Link>
      </div>
    </div>
  )
}

export default CreateReviewBanner
