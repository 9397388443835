export const RECENT_REVIEWS_LIST_REQUEST = 'RECENT_REVIEWS_LIST_REQUEST'
export const RECENT_REVIEWS_LIST_SUCCESS = 'RECENT_REVIEWS_LIST_SUCCESS'
export const RECENT_REVIEWS_LIST_FAIL = 'RECENT_REVIEWS_LIST_FAIL'

export const BRAND_REVIEWS_LIST_REQUEST = 'BRAND_REVIEWS_LIST_REQUEST'
export const BRAND_REVIEWS_LIST_SUCCESS = 'BRAND_REVIEWS_LIST_SUCCESS'
export const BRAND_REVIEWS_LIST_FAIL = 'BRAND_REVIEWS_LIST_FAIL'

export const SUBMIT_REVIEW_REQUEST = 'SUBMIT_REVIEW_REQUEST'
export const SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS'
export const SUBMIT_REVIEW_FAIL = 'SUBMIT_REVIEW_FAIL'
export const REVIEW_SUBMISSION_FEEDBACK_ACKNOWLEDGE =
  'REVIEW_SUBMISSION_FEEDBACK_ACKNOWLEDG'

export const RATING_TYPES = [
  { key: 'dryGrip', label: 'Dry Grip' },
  { key: 'wetGrip', label: 'Wet Grip' },
  { key: 'handling', label: 'Handling' },
  { key: 'tyreWear', label: 'Tyre Wear' },
  { key: 'noise', label: 'Noise' },
  { key: 'fuelSaving', label: 'Fuel Saving' },
  { key: 'valueForMoney', label: 'Great Value' },
]
