import React from 'react'
import CreatableSizeSelect from '../CreatableSizeSelect'

const TyreWidthField = ({ input }) => (
  <CreatableSizeSelect
    onChange={input.onChange}
    min={155}
    max={355}
    step={10}
  />
)

export default TyreWidthField
