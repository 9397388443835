import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchRecentReviews } from '../actions/reviewAction'
import RecentReviews from '../components/Home/RecentReviews'
import Search from '../components/Home/Search'
import { Helmet } from 'react-helmet'
import { track } from '../actions/analyticsActions'

const HomeScreen = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(track('Landing Page'))
    dispatch(fetchRecentReviews())
  }, [dispatch])

  return (
    <>
      <Helmet>
        <title>Drive Further Tyre Reviews</title>
        <meta
          name='description'
          content='Our mission is to empower consumers with real, verified tyre reviews. Read, write and share tyre reviews online.'
        />
      </Helmet>
      <Search />
      <RecentReviews />
    </>
  )
}

export default HomeScreen
