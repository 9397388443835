import React from 'react'
import { FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap'

const MileageField = ({ input }) => {
  return (
    <>
      <OverlayTrigger
        placement='bottom'
        overlay={<Tooltip>{input.value || 0} Km</Tooltip>}
      >
        <FormControl
          className='mileage-bar'
          type='range'
          min={0}
          max={80000}
          value={input.value || 0}
          onChange={input.onChange}
        />
      </OverlayTrigger>
      <div className='ml-sm-3' md='auto'>
        {input.value || 0} km
      </div>
    </>
  )
}

export default MileageField
