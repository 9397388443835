import React from 'react'
import { Row, Col } from 'react-bootstrap'
import MyRatingStar from '../MyRatingStar'

const AggregatedRatingRow = ({ type, reviews }) => {
  const filteredReviews = reviews.filter((review) => review[type.key] > 0)
  const sum = filteredReviews.reduce((acc, review) => acc + review[type.key], 0)
  const avg = filteredReviews.length === 0 ? 0 : sum / filteredReviews.length

  return (
    <Row>
      <Col xs="5" lg="2">
        {type.label}
      </Col>
      <Col>
        <MyRatingStar rating={avg} sm />
        <span className="ml-3" style={{verticalAlign: 'sub'}}>{avg !== 0 && avg.toFixed(1)}</span>
      </Col>
    </Row>
  )
}

export default AggregatedRatingRow
