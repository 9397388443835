import API from '../api'
import {
  BRAND_REVIEWS_LIST_FAIL,
  BRAND_REVIEWS_LIST_REQUEST,
  BRAND_REVIEWS_LIST_SUCCESS,
  RECENT_REVIEWS_LIST_FAIL,
  RECENT_REVIEWS_LIST_REQUEST,
  RECENT_REVIEWS_LIST_SUCCESS,
  SUBMIT_REVIEW_FAIL,
  SUBMIT_REVIEW_REQUEST,
  SUBMIT_REVIEW_SUCCESS,
} from '../constants/reviewConstant'
import { extractErrorMessage } from '../utils'

export const fetchRecentReviews = () => async (dispatch) => {
  try {
    dispatch({ type: RECENT_REVIEWS_LIST_REQUEST })
    const { data } = await API.get('/api/reviews?n=10')
    dispatch({
      type: RECENT_REVIEWS_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: RECENT_REVIEWS_LIST_FAIL,
      payload: extractErrorMessage(error),
    })
  }
}

export const fetchReviewsByBrand = (brandName) => async (dispatch) => {
  try {
    dispatch({ type: BRAND_REVIEWS_LIST_REQUEST })
    const { data } = await API.get(`/api/reviews?brand=${brandName}`)
    dispatch({
      type: BRAND_REVIEWS_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: BRAND_REVIEWS_LIST_FAIL,
      payload: extractErrorMessage(error),
    })
  }
}

export const submitReview = (review) => async (dispatch) => {
  try {
    dispatch({ type: SUBMIT_REVIEW_REQUEST })
    await API.post('/api/reviews', review)
    dispatch({
      type: SUBMIT_REVIEW_SUCCESS,
      payload: 'Your review has been successfully submitted.',
    })
  } catch (error) {
    dispatch({ type: SUBMIT_REVIEW_FAIL, payload: extractErrorMessage(error) })
    throw error
  }
}
