import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import { change } from 'redux-form'

const TitleField = ({ input, meta }) => {
  const dispatch = useDispatch()

  const {
    evaluation: { values },
  } = useSelector((state) => state.form)

  useEffect(() => {
    if (!meta.visited) {
      const trimedComment = values.comment ? values.comment.trim() : ''
      const title =
        trimedComment.substring(0, 40) +
        (trimedComment.length > 40 ? '...' : '')
      dispatch(change('evaluation', input.name, title))
    }
  }, [values.comment, meta.visited, input.name, dispatch])

  return (
    <>
      <FormControl
        placeholder='Write a title for your review'
        {...input}
        isInvalid={meta.touched && meta.error}
      />
      <FormControl.Feedback type='invalid'>{meta.error}</FormControl.Feedback>
    </>
  )
}

export default TitleField
