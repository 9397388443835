import React from 'react'
import { useDispatch } from 'react-redux'
import MyRatingStar from '../MyRatingStar'
import { Badge } from 'react-bootstrap'
import { change } from 'redux-form'

const RatingStarField = ({ input, sm, clearable }) => {
  const dispatch = useDispatch()

  const clearHandler = () => {
    dispatch(change('evaluation', input.name, undefined))
  }

  return (
    <>
      <MyRatingStar
        sm={sm}
        rating={input.value || 0}
        changeRating={input.onChange}
      />
      {clearable && (
        <div
          className='d-inline-block ml-3'
          style={{
            visibility: input.value ? 'visible' : 'hidden',
          }}
        >
          <Badge variant='light' pill onClick={clearHandler}>
            <i className='fas fa-times' />
          </Badge>
        </div>
      )}
    </>
  )
}

export default RatingStarField
