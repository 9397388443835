import React from 'react'
import CreatableSelect from 'react-select/creatable'

const CreateLabel = ({ value }) => <>{value}</>

const CreatableSizeSelect = ({
  onChange,
  min,
  max,
  step,
  decimalPlace = 0,
}) => {
  const values = []

  for (let i = min; i <= max; i += step) {
    values.push(i.toFixed(decimalPlace))
  }

  const options = values.map((value) => ({ value: value, label: value }))

  return (
    <CreatableSelect
      styles={{
        control: (provided) => ({ ...provided, borderRadius: 0 }),
      }}
      onChange={(option) =>
        onChange(option === null ? undefined : Number(option.value))
      }
      isValidNewOption={(input) => {
        const inputNum = Number(input)

        if (isNaN(inputNum) || inputNum < 1 || inputNum > 2000) return undefined

        return inputNum
      }}
      options={options}
      isClearable
      formatCreateLabel={(value) => <CreateLabel value={value} />}
      createOptionPosition='first'
      noOptionsMessage={() => '1 ~ 2000'}
      className='react-select-container'
      classNamePrefix='react-select'
      placeholder={`${min} - ${max}`}
    />
  )
}

export default CreatableSizeSelect
