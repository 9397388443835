import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'

const ReviewCardLoader = () => {
  return (
    <Card className='my-3'>
      <Card.Body>
        <div className='mb-3 ' style={{ width: 250 }}>
          <ContentLoader speed={0.5} viewBox='0 0 100 20'>
            <rect x='0' y='0' width='75' height='20' />
            <rect x='80' y='0' width='20' height='20' />
          </ContentLoader>
        </div>
        <Card.Title>
          <ContentLoader speed={0.5} viewBox='0 0 100 4'>
            <rect x='0' y='0' width='30' height='3' />
          </ContentLoader>
        </Card.Title>

        <Row>
          <Col xs='12' lg='8' className='mb-3'>
            <ContentLoader speed={0.5} viewBox='0 0 100 30'>
              <rect x='0' y='0' width='80' height='30' />
            </ContentLoader>
          </Col>
          <Col>
            <ContentLoader speed={0.5} viewBox='0 0 100 12'>
              <rect x='0' y='0' width='70' height='10' />
            </ContentLoader>
            <ContentLoader speed={0.5} viewBox='0 0 100 12'>
              <rect x='0' y='0' width='70' height='10' />
            </ContentLoader>
            <ContentLoader speed={0.5} viewBox='0 0 100 12'>
              <rect x='0' y='0' width='70' height='10' />
            </ContentLoader>
            <ContentLoader speed={0.5} viewBox='0 0 100 12'>
              <rect x='0' y='0' width='70' height='10' />
            </ContentLoader>
          </Col>
        </Row>
        <br />
        <div style={{ width: 300 }}>
          <ContentLoader speed={0.5} viewBox='0 0 100 15'>
            <rect x='0' y='0' width='50' height='4' />
            <rect x='55' y='0' width='45' height='4' />
            <rect x='0' y='6' width='100' height='4' />
          </ContentLoader>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ReviewCardLoader
