import React, { useState } from 'react'
import { withRouter, useParams, useHistory } from 'react-router-dom'
import { useSelector, connect, useDispatch } from 'react-redux'
import queryString from 'query-string'
import { reduxForm, Field } from 'redux-form'
import { Form, Row, Col, InputGroup, Button, Modal, Image, Spinner } from 'react-bootstrap'
import RatingStarField from '../Inputs/RatingStarField'
import { RATING_TYPES } from '../../constants/reviewConstant'
import MileageField from '../Inputs/MileageField'
import RepurchaseField from '../Inputs/RepurchaseField'
import CommentField from '../Inputs/CommentField'
import { isEmail, required } from '../../utils/validations'
import TitleField from '../Inputs/TitleField'
import EmailField from '../Inputs/EmailField'
import TyreWidthField from '../Inputs/TyreWidthField'
import TyreProfileField from '../Inputs/TyreProfileField'
import TyreRimField from '../Inputs/TyreRimField'
import { Link } from 'react-router-dom'
import tyreSizeImage from '../../images/where-to-find-my-tyre-size.png'
import { submitReview } from '../../actions/reviewAction'

const REPURCHASE_DICT = { 1: true, 2: false, 3: null }

const EvaluateForm = ({ handleSubmit, tyreId }) => {
  const dispatch = useDispatch()

  const { loading } = useSelector((state) => state.submitReview)

  const history = useHistory()

  const { slug } = useParams()

  const [show, setShow] = useState(false)

  const { evaluation } = useSelector((state) => state.form)

  const onSubmit = async (values) => {
    const review = { ...values }
    review.repurchase = REPURCHASE_DICT[review.repurchase]
    review.mileage && (review.mileage = Number(review.mileage))

    try {
      await dispatch(
        submitReview({
          tyreId,
          ...review,
        })
      )
      history.push(`/reviews/${slug}`)
    } catch (error) {}
  }

  return (
    <>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className='mb-4'>
          <Col>
            <div className='mb-3 small text-muted'>
              <div>Thank you for taking a few minutes to share your experience.</div>
              <div>Your review will help other drivers find the right tyres for their car.</div>
            </div>
            <h2>Rate your tyre experience</h2>
            <Field name='overallRating' component={RatingStarField} />
          </Col>
        </Row>
        {evaluation?.values?.overallRating && (
          <>
            {RATING_TYPES.map((type) => (
              <Row key={type.key} className='mb-3 mb-sm-2'>
                <Col xs='5' lg='4'>
                  <span className='mr-3'>{type.label}</span>
                </Col>
                <Col>
                  <Field name={type.key} component={RatingStarField} sm clearable />
                </Col>
              </Row>
            ))}
            <Row className='my-4'>
              <Col>
                <h5>What was the size of the tyres? </h5>
                <h5 className='small text-muted mb-3' onClick={() => setShow(true)} role='button'>
                  How to read your tyre size
                  <i className='fas fa-info-circle pl-1'></i>
                </h5>
                <Row className='mb-1'>
                  <Col xs='5' md='3' className='align-self-center'>
                    Width
                  </Col>
                  <Col xs='6' md='3'>
                    <Field name='width' component={TyreWidthField} />
                  </Col>
                </Row>
                <Row className='mb-1'>
                  <Col xs='5' md='3' className='align-self-center'>
                    Profile
                  </Col>
                  <Col xs='6' md='3'>
                    <Field name='profile' component={TyreProfileField} />
                  </Col>
                </Row>
                <Row className='mb-1'>
                  <Col xs='5' md='3' className='align-self-center'>
                    Rim
                  </Col>
                  <Col xs='6' md='3'>
                    <Field name='rimSize' component={TyreRimField} />
                  </Col>
                </Row>
                <h5 className='small text-muted mt-3'>Leave fields blank if you are unsure</h5>
              </Col>
            </Row>
            <Row className='my-4'>
              <Col>
                <h5 className='mb-3'>How many kilometres have you driven with these tyres?</h5>
                <InputGroup>
                  <Field name='mileage' component={MileageField} />
                </InputGroup>
              </Col>
            </Row>
            <Row className='my-4'>
              <Col>
                <Form.Label className='h5 d-block mb-3'>Will you buy these tyres again?</Form.Label>
                <Field name='repurchase' component={RepurchaseField} />
              </Col>
            </Row>
          </>
        )}

        <div className={evaluation?.values?.repurchase ? null : 'd-none'}>
          <div>
            <Row className='my-4'>
              <Col>
                <Form.Label className='h5 mb-3'>Tell us about your experience *</Form.Label>
                <InputGroup className='mb-3'>
                  <Field name='comment' component={CommentField} validate={required} />
                </InputGroup>
                <Form.Label className='h5 mb-3'>Give your review a title *</Form.Label>
                <InputGroup className='mb-3'>
                  <Field name='title' component={TitleField} validate={required} />
                </InputGroup>
              </Col>
            </Row>
          </div>
          <Row className='my-4'>
            <Col>
              <Form.Label className='h5 mb-3'>Verify your email to post your review *</Form.Label>
              <InputGroup className='mb-3'>
                <Field
                  name='email'
                  component={EmailField}
                  placeholder='Enter your contact email'
                  validate={[required, isEmail]}
                />
              </InputGroup>
              <Button className='btn-lg' block type='submit' disabled={loading}>
                {loading ? <Spinner animation='border' /> : 'Submit Review'}
              </Button>
              <div className='small text-muted mt-2'>
                By submitting a review, you agree to our{' '}
                <Link to='/privacy-policy' target='_blank'>
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link to='/terms' target='_blank'>
                  Terms & Conditions
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>How to read your tyre size</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src={tyreSizeImage} fluid />
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = (state, { location }) => {
  const parsedQuery = queryString.parse(location.search)
  const overallRating = parsedQuery.overallRating ? Number(parsedQuery.overallRating) : undefined

  return { initialValues: { overallRating } }
}

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({ form: 'evaluation', enableReinitialize: true })(EvaluateForm)
  )
)
