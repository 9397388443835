import React from 'react'
import ContentLoader from 'react-content-loader'

const BrandLoader = () => {
  return (
    <div style={{ width: 220 }}>
      <ContentLoader speed={0.5} viewBox='0 0 100 25'>
        <rect x='5' y='0' width='100' height='25' />
      </ContentLoader>
    </div>
  )
}

export default BrandLoader
