import { BRAND_LIST_FAIL, BRAND_LIST_REQUEST, BRAND_LIST_SUCCESS } from '../constants/brandConstant'

export const brandsReducer = (state = { loading: false, data: [] }, action) => {
  switch (action.type) {
    case BRAND_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case BRAND_LIST_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      }
    case BRAND_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
