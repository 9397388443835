import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form, Row, Col, InputGroup, Button, Image, Modal, ListGroup } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Select from 'react-select'
import heroImage from '../../images/tyre-image-3.png'

const Search = () => {
  const history = useHistory()

  const [search, setSearch] = useState('')

  const [showMoreBrands, setShowMoreBrands] = useState(false)

  const { tyres } = useSelector((state) => state.tyres)

  const { data: brands } = useSelector((state) => state.brands)

  const options = useMemo(() => {
    const names = tyres.map((tyre) => `${tyre.brand.name} ${tyre.pattern}`)
    return [...new Set(names)].sort().map((tyreName) => ({ value: tyreName, label: tyreName }))
  }, [tyres])

  const searchHandler = (e) => {
    e.preventDefault()
    const index = tyres.findIndex((tyre) => search === `${tyre.brand.name} ${tyre.pattern}`)
    const tyre = index >= 0 ? tyres[index] : null
    if (tyre) history.push(`/reviews/${tyre.slug}`)
  }

  const closeModal = () => setShowMoreBrands(false)

  return (
    <>
      <div
        className='hero-image'
        style={{
          backgroundImage: `url(${heroImage})`,
        }}
      >
        <div className='hero-card'>
          <h1 className='hero-heading'>Tyres made easy</h1>
          <p className='hero-subheading my-3'>Read, write and share tyre reviews.</p>
          <Form className='my-4' onSubmit={searchHandler} aria-label='form'>
            <Form.Row>
              <InputGroup as={Col} md='6' size='lg'>
                <Select
                  className='react-select-container'
                  classNamePrefix='react-select'
                  value={search && { label: search, value: search }}
                  onChange={(option) => setSearch(option.value)}
                  options={options}
                  noOptionsMessage={() => {
                    return 'Sorry, could not find any match in brand or pattern.'
                  }}
                  placeholder='Find a tyre...'
                />
                <InputGroup.Append>
                  <Button type='submit'>Search</Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Row>
          </Form>
          <p className='hero-subheading mt-3'>
            Popular tyre brands |{' '}
            <span className='more-brands' onClick={() => setShowMoreBrands(true)}>
              Show more...
            </span>
          </p>
          <Row>
            <Col lg='9' xl='8'>
              {brands
                .sort((a, b) => a.rank - b.rank)
                .slice(0, 12)
                .map((brand) => (
                  <LinkContainer to={`/${brand.name}`} key={brand._id}>
                    <Image
                      className='hero-brand-logos mb-4 my-sm-2 mr-2 mr-sm-4'
                      alt={`Brand logo of ${brand.name}`}
                      src={brand.image}
                    />
                  </LinkContainer>
                ))}
            </Col>
          </Row>
        </div>
      </div>
      <Modal show={showMoreBrands} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Search by tyre brands</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0'>
          <ListGroup variant='flush'>
            {brands.map((brand) => (
              <LinkContainer key={brand._id} to={`/${brand.name}`}>
                <ListGroup.Item action className='text-center'>
                  {brand.name}
                </ListGroup.Item>
              </LinkContainer>
            ))}
            <ListGroup.Item action className='text-right text-muted mt-3' onClick={closeModal}>
              <u>Close</u>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Search
