import React from 'react'
import { Card } from 'react-bootstrap'

const PrivacyPolicy = () => {
  return (
    <>
      <h4 className='my-4 text-center'>Privacy Policy</h4>
      <Card className='border-0 m-sm-5'>
        <Card.Body>
          <p>
            This policy (together with any other documents referred to in it) sets out the basis on
            which any personal data we collect from you, or that you provide to us will be processed
            by us. Please read the following carefully to understand our views and practices
            regarding your personal data and how we will treat it.{' '}
          </p>
          <p>
            By your access of this Site and/or use of the Services, you hereby agree to be legally
            bound by these Website Conditions. If you do not accept these Website Conditions, please
            leave the Site and discontinue use of the Services immediately.{' '}
          </p>
          <ol>
            <li>
              Information collection
              <ul>
                <li>
                  In order to provide a better service to you, we may collect and process the
                  following data about you:
                  <ul>
                    <li>
                      information that you provide by filling in forms on our site. This includes
                      information provided at the time of registering to use our site, subscribing
                      to our service, posting material or requesting further services. We may also
                      ask you for information at other times, for example in connection with a
                      promotion or when you report a problem with our site;{' '}
                    </li>
                    <li>if you contact us, we may keep a record of that correspondence; </li>
                    <li>
                      we may also ask you to complete surveys that we use for research purposes,
                      although you do not have to respond to them;{' '}
                    </li>
                    <li>
                      details of transactions you carry out through our site and of the fulfilment
                      of your orders;{' '}
                    </li>
                    <li>details of your visits to our site and the resources that you access. </li>
                  </ul>
                </li>
                <li>
                  We only retain personal data for so long as it is necessary. Data may be archived
                  as long as the purpose for which the data was used still exists.{' '}
                </li>
              </ul>
            </li>
            <li>
              Uses made of the information
              <ul>
                <li>
                  The purposes for which information may be used by us in and outside Malaysia
                  include:
                  <ul>
                    <li>
                      ensuring that content from our site is presented in the most effective manner
                      for you and for your computer;{' '}
                    </li>
                    <li>
                      providing you with alerts, newsletter, education materials or information that
                      you requested or signed up to;{' '}
                    </li>
                    <li>
                      carrying out our obligations arising from any contracts entered into between
                      you and us;{' '}
                    </li>
                    <li>
                      allowing you to participate in interactive features of our service, when you
                      choose to do so;{' '}
                    </li>
                    <li>
                      designing and conducting surveys/questionnaires for client
                      profiling/segmentation, statistical analysis, improving and furthering the
                      provision our products and services;
                    </li>
                    <li>
                      complying with laws and regulations applicable to us or any of our affiliates
                      in or outside Malaysia;{' '}
                    </li>
                    <li>
                      legal proceedings, including collecting overdue amounts and seeking
                      professional advices;{' '}
                    </li>
                    <li>
                      researching, designing and launching services or products including
                      seminars/events/forums;{' '}
                    </li>
                    <li>
                      promoting and marketing services and products subject to your exercise of the
                      opt-out right;{' '}
                    </li>
                    <li>purposes directly related or incidental to the above. </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Disclosure of your information
              <ul>
                <li>
                  We will keep the personal data we hold confidential but may provide information
                  to:
                  <ul>
                    <li>
                      personnel, agents, advisers, auditors, contractors, financial institutions,
                      and service providers in connection with our operations or services;{' '}
                    </li>
                    <li>
                      our overseas offices, affiliates, business partners and counterparts (if any);{' '}
                    </li>
                    <li>persons under a duty of confidentiality to us; </li>
                    <li>
                      persons to whom we are required to make disclosure under applicable laws and
                      regulations in or outside Malaysia; or{' '}
                    </li>
                    <li>
                      actual or proposed transferees or participants of our services in or outside
                      Malaysia.{' '}
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Cookies
              <ul>
                <li>
                  We will keep the personal data we hold confidential but may provide information
                  to:
                  <ul>
                    <li>
                      Our website uses cookies to distinguish you from other users of our website.
                      This helps us to provide you with a good experience when you browse our
                      website and also allows us to improve our site. By continuing to browse the
                      site, you are agreeing to our use of cookies.{' '}
                    </li>
                    <li>
                      A cookie is a small file of letters and numbers that we store on your browser
                      or the hard drive of your computer if you agree. Cookies contain information
                      that is transferred to your computer's hard drive.
                    </li>
                    <li>
                      We use the following cookies:
                      <ul>
                        <li>
                          Strictly necessary cookies. These are cookies that are required for the
                          operation of our website. They include, for example, cookies that enable
                          you to log into secure areas of our website, use a shopping cart or make
                          use of e-billing services.{' '}
                        </li>
                        <li>
                          Analytical/performance cookies. They allow us to recognise and count the
                          number of visitors and to see how visitors move around our website when
                          they are using it. This helps us to improve the way our website works, for
                          example, by ensuring that users are finding what they are looking for
                          easily.{' '}
                        </li>
                        <li>
                          Functionality cookies. These are used to recognise you when you return to
                          our website. This enables us to personalise our content for you, greet you
                          by name and remember your preferences (for example, your choice of
                          language or region).{' '}
                        </li>
                        <li>
                          Targeting cookies. These cookies record your visit to our website, the
                          pages you have visited and the links you have followed. We will use this
                          information to make our website and the advertising displayed on it more
                          relevant to your interests. We may also share this information with third
                          parties for this purpose.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Please note that third parties (including, for example, advertising networks
                      and providers of external services like web traffic analysis services) may
                      also use cookies, over which we have no control. These cookies are likely to
                      be analytical/performance cookies or targeting cookies.{' '}
                    </li>
                    <li>
                      Our website uses Google Analytics, a web traffic analysis service provided by
                      Google Inc. ("Google"). Please refer to
                      http://www.google.com/policies/privacy/partners to find out more about how
                      Google uses data when you use our website and how to control the information
                      sent to Google.
                    </li>
                    <li>
                      You block cookies by activating the setting on your browser that allows you to
                      refuse the setting of all or some cookies. However, if you use your browser
                      settings to block all cookies (including essential cookies) you may not be
                      able to access all or parts of our site.{' '}
                    </li>
                    <li>
                      Furthermore, you can prevent Google's collection and processing of data by
                      using the Google Ads Settings page or downloading and installing their browser
                      plug-in (https://tools.google.com/dlpage/gaoptout).
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Security of Personal Data
              <ul>
                <li>
                  We will take reasonable steps to protect personal data in our possession or under
                  our control by making reasonable security arrangements to prevent unauthorised
                  access, collection, use and disclosure of these personal data.
                </li>
              </ul>
            </li>
            <li>
              Changes to our Privacy Policy
              <ul>
                <li>
                  Any changes we may make to our Privacy Policy in the future will be posted on this
                  page. Your continued use of our services constitutes your agreement to this
                  Privacy Policy and any updates.
                </li>
              </ul>
            </li>
            <li>
              Your consent and rights
              <ul>
                <li>
                  By using our service, making an application or visiting our website, you consent
                  to the collection and use of your information and other activities as outlined in
                  this policy.
                </li>
                <li>
                  Under the Personal Data Protection Act (the "PDPA"), individuals have the right:
                  <ul>
                    <li>
                      to check whether we hold personal data about you and to access such data;
                    </li>
                    <li>
                      to require us to correct as soon as reasonably practicable any data relating
                      to you which is inaccurate;
                    </li>
                    <li>
                      to ascertain our policies and practices in relation to personal data and the
                      kind of personal data held by us; and
                    </li>
                    <li>
                      to object to the use of your personal data for marketing purposes and we shall
                      not use your personal data for marketing purposes after you communicate your
                      objection to us.
                    </li>
                  </ul>
                </li>
                <li>
                  You may exercise your opt-out right by notifying us if you wish to object to the
                  use of your personal data for direct marketing purposes. Please send requests for
                  such objections, access to data, correction of data, information regarding
                  policies and practices and kinds of data held, questions or complaints to:
                  support@drivefurther.asia
                </li>
              </ul>
            </li>
            <li>
              Governing law and jurisdiction
              <ul>
                <li>
                  Nothing in this Privacy Policy shall limit the rights of the data subject under
                  the PDPA. This Privacy Policy shall be governed by the laws of Malaysia. You agree
                  to submit to the exclusive jurisdiction of the Malaysia courts.
                </li>
              </ul>
            </li>
          </ol>
        </Card.Body>
      </Card>
    </>
  )
}

export default PrivacyPolicy
