import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import Header from './components/Header'
import ReviewScreen from './screens/ReviewScreen'
import EvaluateScreen from './screens/EvaluateScreen'
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import { fetchTyres } from './actions/tyreActions'
import TermsScreen from './screens/TermsScreen'
import PrivacyPolicy from './screens/PrivacyPolicy'
import BrandScreen from './screens/BrandScreen'
import { fetchBrands } from './actions/brandActions'

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTyres())
    dispatch(fetchBrands())
  }, [dispatch])

  return (
    <Router>
      <ScrollToTop />
      <Header />
      <main>
        <Switch>
          <Route path='/' component={HomeScreen} exact />
          <Route path='/terms' component={TermsScreen} exact />
          <Route path='/privacy-policy' component={PrivacyPolicy} exact />
          <Route path='/reviews/:slug' component={ReviewScreen} />
          <Route path='/evaluate/:slug' component={EvaluateScreen} />
          <Route path='/:name' component={BrandScreen} />
        </Switch>
      </main>
      <Footer />
    </Router>
  )
}

export default App
