import API from '../api'
import { TRACKING_FAIL, TRACKING_REQUEST, TRACKING_SUCCESS } from '../constants/analyticsConstant'
import { extractErrorMessage } from '../utils'

export const track = (event) => async (dispatch) => {
  try {
    dispatch({ type: TRACKING_REQUEST })
    await API.post('/api/analytics', {event})
    dispatch({ type: TRACKING_SUCCESS })
  } catch (error) {
    dispatch({
      type: TRACKING_FAIL,
      payload: extractErrorMessage(error),
    })
  }
}
